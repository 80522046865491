import {StyleExtractor} from "@kubric/utils";
import {GatsbyImage} from "gatsby-plugin-image";
import React from "react";

import * as styles from "./styles.module.scss";

const OtherPagesSection = ({data}) => {
  const styler = new StyleExtractor(styles);
  const {visibility = false, heading, description, features} = data || {};
  if (!visibility) {
    return null;
  }
  return (
    <div className={styler.get("sectionWrap", "full-bleed")}>
      <h2 className={styler.get("heading")}>{heading}</h2>
      <div className={styler.get("description")}>{description}</div>
      <div className={styler.get("cards")}>
        {features.map(({content, title, _key, image}) => (
          <div className={styler.get("card")} key={_key}>
            <div className={styler.get("imageContainer")}>
              <GatsbyImage image={image.image.asset.gatsbyImageData} />
            </div>
            <div className={styler.get("cardHeading")}>{title}</div>
            <div className={styler.get("cardDescription")}>{content}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OtherPagesSection;
