import {StyleExtractor} from "@kubric/utils";
import {GatsbyImage} from "gatsby-plugin-image";
import React from "react";

import * as styles from "./styles.module.scss";

const Section2 = ({data}) => {
  const styler = new StyleExtractor(styles);
  const {description, heading, subHeading, features, image} = data;
  return (
    <div className={styler.get("sectionWrap", "full-bleed")}>
      <div className={styler.get("wrapper")}>
        <h2 className={styler.get("heading")}>{heading}</h2>
        <div className={styler.get("description")}>{description}</div>
        <div className={styler.get("content")}>
          <div className={styler.get("imageContainer")}>
            <GatsbyImage
              alt={image.alt}
              image={image.image.asset.gatsbyImageData}
            />
          </div>
          <div className={styler.get("infoContainer")}>
            <div className={styler.get("infoHeading")}>{subHeading}</div>
            <div className={styler.get("infoContent")}>
              {features.map(({content, title}) => (
                <div className={styler.get("info")}>
                  <p className={styler.get("heading")}>
                    <span />
                    {title}
                  </p>
                  <p className={styler.get("description")}>{content}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section2;
