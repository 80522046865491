// extracted by mini-css-extract-plugin
export var container = "styles-module--container--3Ql5g";
export var dot = "styles-module--dot--lYE72";
export var graphicsWrapper = "styles-module--graphicsWrapper--2hJUq";
export var heading = "styles-module--heading--11L-2";
export var hideInDesktop = "styles-module--hideInDesktop--1eKP_";
export var hideInMobile = "styles-module--hideInMobile--2zSIT";
export var image = "styles-module--image--3S8Br";
export var imageBox = "styles-module--imageBox--1q_PI";
export var line = "styles-module--line--2NKc-";
export var mImage = "styles-module--mImage--2Ptv3";
export var scrollFrame = "styles-module--scrollFrame---yzSA";
export var show = "styles-module--show--3GNGZ";
export var showOnlyBottomFrame = "styles-module--showOnlyBottomFrame--1ptzN";
export var slide = "styles-module--slide--3XFKS";
export var slideWrapper = "styles-module--slideWrapper--dQCp6";
export var wrapper = "styles-module--wrapper--3rSAR";