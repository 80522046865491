// extracted by mini-css-extract-plugin
export var bottom = "styles-module--bottom--1k-BY";
export var card = "styles-module--card--ZIiYZ";
export var cardContent = "styles-module--cardContent--2Yt4n";
export var cardHeader = "styles-module--cardHeader--1GB_H";
export var cards = "styles-module--cards--2tn8v";
export var description = "styles-module--description--3zojW";
export var designElement = "styles-module--designElement--HIupi";
export var heading = "styles-module--heading--3nCsl";
export var imageContainer = "styles-module--imageContainer--sq234";
export var sectionWrap = "styles-module--sectionWrap--1T5_d";
export var top = "styles-module--top--3C7fr";