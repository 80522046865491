import {StyleExtractor, isNull} from "@kubric/utils";
import {Link} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image";
import React from "react";

import * as styles from "./styles.module.scss";

function isInViewport(el) {
  const rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.bottom - 500 <=
      (window.innerHeight || document.documentElement.clientHeight)
  );
}

const ScrollFeatures = ({data}) => {
  const styler = new StyleExtractor(styles);
  const {heading, features = []} = data;
  const [viewIndex, setViewIndex] = React.useState(-1);
  const [scrollFrameCSS, setScrollFrameCSS] = React.useState({});

  const ref = React.useRef([]);
  const containerRef = React.useRef(null);
  const scrollFrameRef = React.useRef(null);
  const firstTime = React.useRef(true);

  React.useEffect(() => {
    const onScroll = () => {
      const boolArray = ref.current.filter((i) => !isNull(i)).map(isInViewport);
      const viewIndexFound = boolArray.findIndex((x) => x === true);
      setViewIndex(viewIndexFound >= 0 ? viewIndexFound : -1);
    };
    if (!ref.current.includes(null) && firstTime.current) {
      onScroll();
      document.addEventListener("scroll", onScroll);
      firstTime.current = false;
    }

    return () => document.removeEventListener("scroll", onScroll);
  }, []);

  React.useEffect(() => {
    const onScroll = () => {
      const top = containerRef.current.getBoundingClientRect().top;
      const bottom = containerRef.current.getBoundingClientRect().bottom;
      if (top < 100 && bottom > 100) {
        document.documentElement.style.setProperty(
          "--header-translate-y",
          "-140px"
        );
      } else {
        document.documentElement.style.setProperty("--header-translate-y", "0");
      }
    };
    if (containerRef.current) {
      onScroll();
      document.addEventListener("scroll", onScroll);
    }

    return () => {
      document.removeEventListener("scroll", onScroll);
      document.documentElement.style.setProperty("--header-translate-y", "0");
    };
  }, []);

  React.useEffect(() => {
    if (viewIndex >= 0 && viewIndex < features.length) {
      const width = scrollFrameRef.current.getBoundingClientRect().width;
      const left = scrollFrameRef.current.getBoundingClientRect().left;
      setScrollFrameCSS({
        left,
        width,
        opacity: 1,
        visibility: "visible",
      });
    } else {
      setScrollFrameCSS({
        opacity: 0,
        visibility: "hidden",
      });
    }
  }, [viewIndex, features.length]);

  return (
    <div ref={containerRef} className={styler.get("container", "full-bleed")}>
      <div
        className={styler.get([
          "scrollFrame",
          viewIndex === 0 && "showOnlyBottomFrame",
        ])}
        style={scrollFrameCSS}
      />
      <h2 className={styler.get("heading")}>{heading}</h2>
      <div className={styler.get("wrapper")}>
        <div className={styles.slideWrapper} ref={scrollFrameRef}>
          {features.map(
            ({content, title, image, featurePageReference, _key}) => {
              return (
                <div
                  ref={(node) => ref.current.push(node)}
                  className={styles.slide}
                  key={_key}
                >
                  <div className={styles.graphicsWrapper}>
                    <div className={styles.dot} />
                    <div className={styles.line} />
                  </div>
                  <div>
                    <h2>{title}</h2>
                    <p>{content}</p>
                    {featurePageReference?.ctaText &&
                      featurePageReference?.page?.slug?.current && (
                        <Link
                          to={`/features/${featurePageReference.page.slug.current}`}
                          className={styles.hideInMobile}
                        >
                          {featurePageReference.ctaText}
                        </Link>
                      )}
                  </div>
                  <div className={styler.get(["mImage"])}>
                    <GatsbyImage image={image.image.asset.gatsbyImageData} />
                  </div>
                  {featurePageReference?.ctaText &&
                    featurePageReference?.page?.slug?.current && (
                      <Link
                        to={`/features/${featurePageReference.page.slug.current}`}
                        className={styles.hideInDesktop}
                      >
                        {featurePageReference.ctaText}
                      </Link>
                    )}
                </div>
              );
            }
          )}
        </div>
        <div className={styles.imageBox}>
          {features.map(({image}, index) => (
            <div
              className={styler.get(["image", viewIndex === index && "show"])}
            >
              <GatsbyImage
                image={image.image.asset.gatsbyImageData}
                alt={image.alt}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ScrollFeatures;
