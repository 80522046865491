import {graphql} from "gatsby";
import React from "react";

import LastSection from "../../components/AboveFooterSection";
import Buffer from "../../components/Buffer";
import Layout from "../../components/Layout";
import StructuredData, {
  StructuredDataType,
} from "../../components/StructuredData";
import CaseStudies from "../../components/pages/hub/CaseStudies";
import Hero from "../../components/pages/hub/Hero";
import OtherPages from "../../components/pages/hub/OtherPagesSection";
import ScrollFeatures from "../../components/pages/hub/ScrollFeatures";
import Section2 from "../../components/pages/hub/Section2";
import Section3 from "../../components/pages/hub/Section3";
import "../../pages/styles.scss";

const HubPage = ({data, pageContext: {data: cmsData}}) => {
  const seoData = data.allSanitySeoMeta.nodes[0];
  const {
    hero,
    section2,
    section3,
    scrollSection,
    caseStudySection,
    otherPagesSection,
    lastSection,
  } = cmsData;
  return (
    <Layout seoData={seoData} headerProps={{showMMCta: true}}>
      <StructuredData
        type={StructuredDataType.PRODUCT}
        data={{key: "features"}}
      />
      <Buffer buffer={81} mobileBuffer={30} />
      <Hero data={hero} />
      <Section2 data={section2} />
      <Section3 data={section3} />
      <ScrollFeatures data={scrollSection} />
      <CaseStudies data={caseStudySection} />
      <OtherPages data={otherPagesSection} />
      <LastSection data={lastSection} />
    </Layout>
  );
};

export const query = graphql`
  query ($tag: String) {
    allSanitySeoMeta(filter: {page: {eq: $tag}}) {
      nodes {
        title
        canUrl
        tags {
          content
          type {
            attributeType
            attributeValue
          }
        }
      }
    }
  }
`;

export default HubPage;
