import {StyleExtractor} from "@kubric/utils";
import {GatsbyImage} from "gatsby-plugin-image";
import React from "react";

import MMCta from "../../../commons/MMCTA";
import * as styles from "./styles.module.scss";

const Hero = ({data}) => {
  const styler = new StyleExtractor(styles);
  const {description, heading, image, mImage} = data;
  return (
    <div className={styler.get("heroWrap", "full-bleed")}>
      <h1 className={styler.get("heading")}>{heading}</h1>
      <div className={styler.get("description")}>{description}</div>
      <div className={styler.get("heroCta")}>
        <MMCta cta='Get Started' v3 footer={false} id='install-mm-app' />
      </div>
      <div className={styler.get("heroImageWrapper")}>
        <div className={styler.get("heroImage")}>
          <GatsbyImage
            alt={image?.alt}
            image={image?.image?.asset?.gatsbyImageData}
          />
        </div>
      </div>
      <div className={styler.get("mHeroImageWrapper")}>
        <div className={styler.get("mHeroImage")}>
          <GatsbyImage
            alt={mImage?.alt}
            image={mImage?.image?.asset?.gatsbyImageData}
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;
