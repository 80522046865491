import {StyleExtractor} from "@kubric/utils";
import {GatsbyImage} from "gatsby-plugin-image";
import React from "react";

import * as styles from "./styles.module.scss";

const styler = new StyleExtractor(styles);

const CaseStudies = ({data}) => {
  const {visibility = false, caseStudies = []} = data || {};
  const [selected, setSelected] = React.useState(0);
  const {designation, author, quote} = caseStudies[selected] || {};

  React.useEffect(() => {
    const timeoutId = setInterval(() => {
      setSelected((prev) => {
        return prev === caseStudies.length - 1 ? 0 : prev + 1;
      });
    }, 3000);
    return () => {
      clearInterval(timeoutId);
    };
  }, []);

  if (!visibility) {
    return null;
  }

  return (
    <div className={styler.get("container", "full-bleed")}>
      <div className={styles.imageGrid}>
        {caseStudies.map(({image, _key}, index) => (
          <button
            onClick={() => setSelected(index)}
            type='button'
            className={styler.get([index === selected && "selected", "button"])}
            key={_key}
          >
            <GatsbyImage
              style={{height: "100%", width: "100%", background: "#fff"}}
              imgStyle={{objectFit: "cover", zIndex: -10, background: "#fff"}}
              image={image.image.asset.gatsbyImageData}
            />
          </button>
        ))}
      </div>
      <div className={styler.get("quoteContainer")}>
        <p className={styles.quote}>{quote}</p>
        <div className={styles.meta}>
          <p className={styles.author}>&#8208; {author}</p>
          <p className={styles.designation}>
            <span style={{opacity: 0}}>&#8208;</span> {designation}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CaseStudies;
