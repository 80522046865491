// extracted by mini-css-extract-plugin
export var content = "styles-module--content--I_0kq";
export var description = "styles-module--description--616ze";
export var heading = "styles-module--heading--De0mD";
export var imageContainer = "styles-module--imageContainer--2ZTtJ";
export var info = "styles-module--info--3te_O";
export var infoContainer = "styles-module--infoContainer--37vdC";
export var infoContent = "styles-module--infoContent--OKco9";
export var infoHeading = "styles-module--infoHeading--3adEN";
export var sectionWrap = "styles-module--sectionWrap--2lZ7k";
export var wrapper = "styles-module--wrapper--2fOU0";